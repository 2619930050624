var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "callRecordContainerFlexColumn" },
    [
      _c(
        "div",
        { staticClass: "callRecordContainerFlexColumn__top" },
        [
          _c("div", { staticClass: "title" }, [_vm._v("通话记录")]),
          _c("el-divider"),
        ],
        1
      ),
      _c(
        "dc-search-panel",
        {
          staticStyle: {
            border: "none",
            "background-color": "white",
            "margin-bottom": "0",
          },
          attrs: { params: _vm.queryParams },
          on: { search: _vm.doSearch, reset: _vm.reset },
        },
        [
          _c(
            "dc-form-item",
            { attrs: { label: "统计时间(起)" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "datetime",
                  placeholder: "选择日期",
                  format: "yyyy-MM-dd HH",
                  "data-format": "yyyy-MM-dd HH",
                  "value-format": "yyyy-MM-dd HH",
                  "popper-class": "customer-date-picker",
                },
                model: {
                  value: _vm.queryParams.startDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryParams, "startDate", $$v)
                  },
                  expression: "queryParams.startDate",
                },
              }),
            ],
            1
          ),
          _c(
            "dc-form-item",
            { attrs: { label: "统计时间(止)" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "datetime",
                  placeholder: "选择日期",
                  format: "yyyy-MM-dd HH",
                  "data-format": "yyyy-MM-dd HH",
                  "value-format": "yyyy-MM-dd HH",
                  "popper-class": "customer-date-picker",
                },
                model: {
                  value: _vm.queryParams.endDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryParams, "endDate", $$v)
                  },
                  expression: "queryParams.endDate",
                },
              }),
            ],
            1
          ),
          _c("dc-input", { attrs: { field: "username", label: "客服名称" } }),
          _c("div", { staticClass: "dc-form-item" }, [
            _c(
              "div",
              {
                staticClass: "dc-form-item__label",
                staticStyle: { width: "100px" },
              },
              [_vm._v("呼叫明细")]
            ),
            _c(
              "div",
              { staticClass: "dc-form-item__body" },
              [
                _c(
                  "el-select",
                  {
                    model: {
                      value: _vm.queryParams.detail,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryParams, "detail", $$v)
                      },
                      expression: "queryParams.detail",
                    },
                  },
                  _vm._l(_vm.selected, function (item) {
                    return _c("el-option", {
                      key: item.detail,
                      attrs: { value: item.detail, label: item.name },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "dc-form-item",
            { attrs: { label: "呼叫方向" } },
            [
              _c(
                "el-select",
                {
                  model: {
                    value: _vm.queryParams.direction,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "direction", $$v)
                    },
                    expression: "queryParams.direction",
                  },
                },
                [
                  _c("el-option", {
                    key: "",
                    attrs: { value: "", label: "全部" },
                  }),
                  _c("el-option", {
                    key: 1,
                    attrs: { value: 1, label: "呼入" },
                  }),
                  _c("el-option", {
                    key: 2,
                    attrs: { value: 2, label: "呼出" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "dc-form-item",
            { attrs: { label: "应答状态" } },
            [
              _c(
                "el-select",
                {
                  model: {
                    value: _vm.queryParams.answerflag,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "answerflag", $$v)
                    },
                    expression: "queryParams.answerflag",
                  },
                },
                [
                  _c("el-option", {
                    key: "",
                    attrs: { value: "", label: "全部" },
                  }),
                  _c("el-option", {
                    key: 1,
                    attrs: { value: 1, label: "接通" },
                  }),
                  _c("el-option", {
                    key: 2,
                    attrs: { value: 2, label: "未接通" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "dc-form-item",
            { attrs: { label: "筛选条件" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "筛选条件" },
                  model: {
                    value: _vm.queryParams.orderBy,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "orderBy", $$v)
                    },
                    expression: "queryParams.orderBy",
                  },
                },
                _vm._l(_vm.selectList, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "dc-form-item",
            { attrs: { label: "排序类型" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: "asc" },
                  model: {
                    value: _vm.queryParams.orderType,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "orderType", $$v)
                    },
                    expression: "queryParams.orderType",
                  },
                },
                [_vm._v("升序")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: "desc" },
                  model: {
                    value: _vm.queryParams.orderType,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "orderType", $$v)
                    },
                    expression: "queryParams.orderType",
                  },
                },
                [_vm._v("降序")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "buttons" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.getNowDay } },
            [_vm._v("当天")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.getMonth } },
            [_vm._v("当月")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.doSearch } },
            [_vm._v("查询")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.reset } },
            [_vm._v("重置")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.exportExcel } },
            [_vm._v("导出Excel")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "callRecordContainerFlexColumn__body" },
        [
          _c(
            "dc-datatable",
            {
              ref: "list",
              attrs: {
                url: _vm.apiUrl,
                params: _vm.queryParams,
                "row-class-name": _vm.noAnswerRow,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "username",
                  width: "80",
                  label: "客服名称",
                  align: "center",
                  fixed: "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "phone",
                  width: "100",
                  label: "客服账号",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "agent",
                  width: "60",
                  label: "分机号",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "usernumber",
                  width: "100",
                  label: "用户号码",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "direction",
                  width: "70",
                  label: "呼叫方向",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("getDirection")(scope.row.direction)
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "detail",
                  width: "120",
                  label: "呼叫结果明细",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: _vm._f("getDetailText")(
                                scope.row.detail
                              ),
                              placement: "top",
                            },
                          },
                          [
                            _c("span", { staticClass: "table-cell-text" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("getDetailText")(scope.row.detail)
                                )
                              ),
                            ]),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "answerflag",
                  width: "70",
                  label: "应答状态",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("getAnswerflagText")(scope.row.answerflag)
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "ringseconds",
                  width: "100",
                  label: "振铃时长(秒)",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "answerseconds",
                  width: "100",
                  label: "应答时长",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("getTimeFormat")(scope.row.answerseconds)
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "starttime",
                  width: "160",
                  label: "会话开始时间",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("dateFilter")(scope.row.starttime)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "ringtime",
                  width: "160",
                  label: "振铃时间",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("dateFilter")(scope.row.ringtime)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "answertime",
                  width: "160",
                  label: "应答时间",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("dateFilter")(scope.row.answertime)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "endtime",
                  width: "160",
                  label: "结束时间",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("dateFilter")(scope.row.endtime)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "srfmsgid",
                  width: "120",
                  label: "录音文件",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.srfmsgid
                          ? _c(
                              "el-button",
                              { attrs: { type: "primary", size: "mini" } },
                              [_vm._v("下载")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }