<template>
  <div class="callRecordContainerFlexColumn">
    <div class="callRecordContainerFlexColumn__top">
      <div class="title">通话记录</div>
      <el-divider />
    </div>
    <dc-search-panel :params="queryParams" style="border: none; background-color: white; margin-bottom: 0" @search="doSearch" @reset="reset">
      <dc-form-item label="统计时间(起)">
        <el-date-picker v-model="queryParams.startDate" type="datetime" placeholder="选择日期" format="yyyy-MM-dd HH" data-format="yyyy-MM-dd HH" value-format="yyyy-MM-dd HH" popper-class="customer-date-picker"></el-date-picker>
      </dc-form-item>
      <dc-form-item label="统计时间(止)">
        <el-date-picker v-model="queryParams.endDate" type="datetime" placeholder="选择日期" format="yyyy-MM-dd HH" data-format="yyyy-MM-dd HH" value-format="yyyy-MM-dd HH" popper-class="customer-date-picker"></el-date-picker>
      </dc-form-item>
      <dc-input field="username" label="客服名称"></dc-input>
      <div class="dc-form-item">
        <div class="dc-form-item__label" style="width: 100px">呼叫明细</div>
        <div class="dc-form-item__body">
          <el-select v-model="queryParams.detail">
            <el-option v-for="item in selected" :key="item.detail" :value="item.detail" :label="item.name"> </el-option>
          </el-select>
        </div>
      </div>
      <dc-form-item label="呼叫方向">
        <el-select v-model="queryParams.direction">
          <el-option key="" value="" label="全部"></el-option>
          <el-option :key="1" :value="1" label="呼入"></el-option>
          <el-option :key="2" :value="2" label="呼出"></el-option>
        </el-select>
      </dc-form-item>
      <dc-form-item label="应答状态">
        <el-select v-model="queryParams.answerflag">
          <el-option key="" value="" label="全部"></el-option>
          <el-option :key="1" :value="1" label="接通"></el-option>
          <el-option :key="2" :value="2" label="未接通"></el-option>
        </el-select>
      </dc-form-item>
      <dc-form-item label="筛选条件">
        <el-select v-model="queryParams.orderBy" placeholder="筛选条件">
          <el-option v-for="item in selectList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </dc-form-item>
      <dc-form-item label="排序类型">
        <el-radio v-model="queryParams.orderType" label="asc">升序</el-radio>
        <el-radio v-model="queryParams.orderType" label="desc">降序</el-radio>
      </dc-form-item>
    </dc-search-panel>

    <div class="buttons">
      <el-button type="primary" @click="getNowDay">当天</el-button>
      <el-button type="primary" @click="getMonth">当月</el-button>
      <el-button type="primary" @click="doSearch">查询</el-button>
      <el-button type="primary" @click="reset">重置</el-button>
      <el-button type="primary" @click="exportExcel">导出Excel</el-button>
    </div>

    <div class="callRecordContainerFlexColumn__body">
      <dc-datatable ref="list" :url="apiUrl" :params="queryParams" :row-class-name="noAnswerRow">
        <el-table-column prop="username" width="80" label="客服名称" align="center" fixed></el-table-column>
        <el-table-column prop="phone" width="100" label="客服账号" align="center"></el-table-column>
        <el-table-column prop="agent" width="60" label="分机号" align="center"></el-table-column>
        <el-table-column prop="usernumber" width="100" label="用户号码" align="center"></el-table-column>
        <el-table-column prop="direction" width="70" label="呼叫方向" align="center">
          <template #default="scope">
            {{ scope.row.direction | getDirection }}
          </template>
        </el-table-column>
        <!--        <el-table-column prop="serviceid" width="120" label="本次呼叫所归属的服务号" align="center"></el-table-column>-->
        <el-table-column prop="detail" width="120" label="呼叫结果明细" align="center">
          <template #default="scope">
            <el-tooltip class="item" effect="dark" :content="scope.row.detail | getDetailText" placement="top">
              <span class="table-cell-text">{{ scope.row.detail | getDetailText }}</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="answerflag" width="70" label="应答状态" align="center">
          <template #default="scope">
            {{ scope.row.answerflag | getAnswerflagText }}
          </template>
        </el-table-column>
        <el-table-column prop="ringseconds" width="100" label="振铃时长(秒)" align="center"></el-table-column>
        <el-table-column prop="answerseconds" width="100" label="应答时长" align="center">
          <template #default="scope">
            {{ scope.row.answerseconds | getTimeFormat }}
          </template>
        </el-table-column>
        <el-table-column prop="starttime" width="160" label="会话开始时间" align="center">
          <template #default="scope">
            {{ scope.row.starttime | dateFilter }}
          </template>
        </el-table-column>
        <el-table-column prop="ringtime" width="160" label="振铃时间" align="center">
          <template #default="scope">
            {{ scope.row.ringtime | dateFilter }}
          </template>
        </el-table-column>
        <el-table-column prop="answertime" width="160" label="应答时间" align="center">
          <template #default="scope">
            {{ scope.row.answertime | dateFilter }}
          </template>
        </el-table-column>
        <el-table-column prop="endtime" width="160" label="结束时间" align="center">
          <template #default="scope">
            {{ scope.row.endtime | dateFilter }}
          </template>
        </el-table-column>

        <el-table-column prop="srfmsgid" width="120" label="录音文件" align="center">
          <template #default="scope">
            <el-button v-if="scope.row.srfmsgid" type="primary" size="mini">下载</el-button>
          </template>
        </el-table-column>
        <!--        <el-table-column prop="callrescode" width="120" label="呼叫结束码" align="center"></el-table-column>-->
      </dc-datatable>
    </div>
  </div>
</template>

<script>
import lodash from "lodash";
import { Message } from "element-ui";
import moment from "moment";
import axios from "axios";
import { tansParams } from "@/api/device/device";
import { getToken } from "@/utils/auth";

export default {
  name: "CallRecord",
  filters: {
    dateFilter(timestamp) {
      if (!timestamp) return "";
      return moment(timestamp, "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss");
    },
    getTimeFormat(val) {
      let h = parseInt(val / 3600);
      h = h > 9 ? `${h}` : `0${h}`;
      let m = parseInt((val - 3600 * h) / 60);
      m = m > 9 ? `${m}` : `0${m}`;
      let s = val - 3600 * h - 60 * m;
      s = s > 9 ? `${s}` : `0${s}`;
      return `${m}分${s}秒`;
    },
    getDetailText(type) {
      if (type === "0002") return "IVR应答";
      else if (type === "0007") return "座席未接通";
      else if (type === "0008") return "用户放弃坐席";
      else if (type === "0009") return "座席久无应答振铃超时";
      else if (type === "0010") return "座席应答";
      else if (type === "0011") return "路由失败";
      else if (type === "0012") return "座席通话中";
      else if (type === "0013") return "acd心跳更新失败";
      else if (type === "0101") return "用户遇忙未接通";
      else if (type === "0102") return "座席放弃";
      else if (type === "0103") return "用户久无应答";
      else if (type === "0104") return "坐席应答后用户应答";
      else if (type === "0105") return "用户应答后坐席应答";
      else if (type === "0106") return "坐席应答";
      else if (type === "0107") return "外呼时，用户接通后，等待找坐席或者等待坐席摘机时，用户前挂机";
      else if (type === "0108") return "余额不足";
      else if (type === "0202") return "呼叫遇忙";
      else if (type === "0203") return "用户不在服务区";
      else if (type === "0204") return "用户无应答";
      else if (type === "0205") return "用户已关机";
      else if (type === "0206") return "号码是空号";
      else if (type === "0211") return "用户停机";
      else if (type === "0212") return "号码过期";
      else if (type === "0213") return "呼叫转移";
      else if (type === "0217") return "ms识别成功，但是识别出的smMsgId在ACD_Out_Sm.xml中没有配置";
      else if (type === "0218") return "外地手机号码前需要加0";
      else if (type === "0220") return "主叫限制";
      else if (type === "0221") return "被叫限制";
      else if (type === "0222") return "呼转限制";
      else if (type === "0223") return "被叫通话中";
      else if (type === "0224") return "用户拒接";
      else if (type === "0225") return "未识别的振铃音";
      else if (type === "1000") return "未呼叫";
      else if (type === "1001") return "路由失败";
      else if (type === "1002") return "呼叫遇忙";
      else if (type === "1003") return "用户不在服务区";
      else if (type === "1004") return "用户无应答";
      else if (type === "1005") return "用户已关机";
      else if (type === "1006") return "号码是空号";
      else if (type === "1007") return "用户应答，成功转接";
      else if (type === "1008") return "用户应答，用户提前挂机";
      else if (type === "1009") return "用户应答，座席忙";
      else if (type === "1010") return "用户应答，座席无应答";
      else if (type === "1011") return "用户停机";
      else if (type === "1012") return "号码过期";
      else if (type === "1013") return "呼叫转移";
      else if (type === "1014") return "超时时间切断通话(若为洗号时，为洗号失败)";
      else if (type === "1015") return "为tone音(若为洗号时，为洗号失败)";
      else if (type === "1017") return "ms识别成功，但是识别出的smMsgId在ACD_Out_Sm.xml中没有配置";
      else if (type === "1018") return "外地手机号码前需要加0";
      else if (type === "1019") return "用户应答，转接坐席失败";
      else if (type === "1020") return "主叫限制";
      else if (type === "1021") return "被叫限制";
      else if (type === "1022") return "呼转限制";
      else if (type === "1023") return "被叫通话中";
      else if (type === "1024") return "用户拒接";
      else if (type === "1025") return "未识别的振铃音";
      else if (type === "1101") return "根据信令判别路由失败";
      else if (type === "1102") return "根据信令判别呼叫遇忙";
      else if (type === "1103") return "根据信令判别出用户不在服务区";
      else if (type === "1104") return "根据信令判别出用户无应答";
      else if (type === "1105") return "根据信令判断用户已关机";
      else if (type === "1106") return "信令失败，没振铃就断(在洗号时此种情况为洗号成功)";
      else if (type === "1107") return "信令上收到408(超时)，都是废号";
      else if (type === "1108") return "线路忙（503Q.850,cause=34,text='Circuit/channelcongestion'）";
      else if (type === "1109") return "线路异常";
      else if (type === "1110") return "根据信令判别为空号,cause为1,22,28,或者code为404";
      else if (type === "1113") return "根据信令判断呼叫转移，如洗号时，用户不通，失败原因值带了呼叫转移；";
      else if (type === "1114") return "媒体服务器异常";
      else if (type === "1200") return "精确预览坐席未接通";
      else if (type === "1201") return "精确预览坐席提前挂机";
      else if (type === "1202") return "精确预览坐席应用提前挂机";
      else if (type === "2001") return "拒绝（路由服务主动拒绝）";
      else if (type === "2002") return "进线在排队中放弃";
      else if (type === "2003") return "进线正常选中坐席";
      else if (type === "2004") return "异常处理（不在服务时间）";
      else if (type === "2005") return "进线直接溢出（排队长度满和无坐席登录）";
      else if (type === "2006") return "进线排队超时";
      else if (type === "2007") return "进线排队转出（质检将路列中的呼叫转走）";
      else if (type === "2008") return "坐席话机失联；";
      else return "";
    },
    getDirection(direction) {
      if (direction === 1) return "呼入";
      else if (direction === 2) return "呼出";
      else return "";
    },
    getAnswerflagText(answerflag) {
      if (answerflag === 1) return "接通";
      else if (answerflag === 2) return "未接通";
      else return "";
    },
  },
  props: {},
  data() {
    return {
      apiUrl: `${this.baseUrls.callCdrReport}/callRecord`,
      params: {},
      queryParams: {},
      selected: [
        { detail: "", name: "全部" },
        { detail: "0002", name: "IVR应答" },
        { detail: "0007", name: "座席未接通" },
        { detail: "0008", name: "用户放弃坐席" },
        { detail: "0009", name: "座席久无应答振铃超时" },
        { detail: "0010", name: "座席应答" },
        { detail: "0011", name: "路由失败" },
        { detail: "0012", name: "座席通话中" },
        { detail: "0013", name: "acd心跳更新失败" },
        { detail: "0101", name: "用户遇忙未接通" },
        { detail: "0102", name: "座席放弃" },
        { detail: "0103", name: "用户久无应答" },
        { detail: "0104", name: "坐席应答后用户应答" },
        { detail: "0105", name: "用户应答后坐席应答" },
        { detail: "0106", name: "坐席应答" },
        { detail: "0107", name: "外呼时，用户接通后，等待找坐席或者等待坐席摘机时，用户前挂机" },
        { detail: "0108", name: "余额不足" },
        { detail: "0202", name: "呼叫遇忙" },
        { detail: "0203", name: "用户不在服务区" },
        { detail: "0204", name: "用户无应答" },
        { detail: "0205", name: "用户已关机" },
        { detail: "0206", name: "号码是空号" },
        { detail: "0211", name: "用户停机" },
        { detail: "0212", name: "号码过期" },
        { detail: "0213", name: "呼叫转移" },
        { detail: "0217", name: "ms识别成功，但是识别出的smMsgId在ACD_Out_Sm.xml中没有配置" },
        { detail: "0218", name: "外地手机号码前需要加0" },
        { detail: "0220", name: "主叫限制" },
        { detail: "0221", name: "被叫限制" },
        { detail: "0222", name: "呼转限制" },
        { detail: "0223", name: "被叫通话中" },
        { detail: "0224", name: "用户拒接" },
        { detail: "0225", name: "未识别的振铃音" },
        { detail: "1000", name: "未呼叫" },
        { detail: "1001", name: "路由失败" },
        { detail: "1002", name: "呼叫遇忙" },
        { detail: "1003", name: "用户不在服务区" },
        { detail: "1004", name: "用户无应答" },
        { detail: "1005", name: "用户已关机" },
        { detail: "1006", name: "号码是空号" },
        { detail: "1007", name: "用户应答，成功转接" },
        { detail: "1008", name: "用户应答，用户提前挂机" },
        { detail: "1009", name: "用户应答，座席忙" },
        { detail: "1010", name: "用户应答，座席无应答" },
        { detail: "1011", name: "用户停机" },
        { detail: "1012", name: "号码过期" },
        { detail: "1013", name: "呼叫转移" },
        { detail: "1014", name: "超时时间切断通话(若为洗号时，为洗号失败)" },
        { detail: "1015", name: "为tone音(若为洗号时，为洗号失败)" },
        { detail: "1017", name: "ms识别成功，但是识别出的smMsgId在ACD_Out_Sm.xml中没有配置" },
        { detail: "1018", name: "外地手机号码前需要加0" },
        { detail: "1019", name: "用户应答，转接坐席失败" },
        { detail: "1020", name: "主叫限制" },
        { detail: "1021", name: "被叫限制" },
        { detail: "1022", name: "呼转限制" },
        { detail: "1023", name: "被叫通话中" },
        { detail: "1024", name: "用户拒接" },
        { detail: "1025", name: "未识别的振铃音" },
        { detail: "1101", name: "根据信令判别路由失败" },
        { detail: "1102", name: "根据信令判别呼叫遇忙" },
        { detail: "1103", name: "根据信令判别出用户不在服务区" },
        { detail: "1104", name: "根据信令判别出用户无应答" },
        { detail: "1105", name: "根据信令判断用户已关机" },
        { detail: "1106", name: "信令失败，没振铃就断(在洗号时此种情况为洗号成功)" },
        { detail: "1107", name: "信令上收到408(超时)，都是废号" },
        { detail: "1108", name: "线路忙" },
        { detail: "1109", name: "线路异常" },
        { detail: "1110", name: "根据信令判别为空号,cause为1,22,28,或者code为404" },
        { detail: "1113", name: "根据信令判断呼叫转移，如洗号时，用户不通，失败原因值带了呼叫转移；" },
        { detail: "1114", name: "媒体服务器异常" },
        { detail: "1200", name: "精确预览坐席未接通" },
        { detail: "1201", name: "精确预览坐席提前挂机" },
        { detail: "1202", name: "精确预览坐席应用提前挂机" },
        { detail: "2001", name: "拒绝（路由服务主动拒绝）" },
        { detail: "2002", name: "进线在排队中放弃" },
        { detail: "2003", name: "进线正常选中坐席" },
        { detail: "2004", name: "异常处理（不在服务时间）" },
        { detail: "2005", name: "进线直接溢出（排队长度满和无坐席登录）" },
        { detail: "2006", name: "进线排队超时" },
        { detail: "2007", name: "进线排队转出（质检将路列中的呼叫转走）" },
        { detail: "2008", name: "坐席话机失联；" },
      ],
      selectList: [
        { label: "会话开始时间", value: "starttime" },
        { label: "结束时间", value: "endtime" },
        { label: "振铃时间", value: "ringtime" },
        { label: "振铃时长", value: "ringseconds" },
        { label: "应答时间", value: "answertime" },
        { label: "应答时长", value: "answerseconds" },
      ],
    };
  },
  created() {
    this.resetPara();
  },
  methods: {
    doSearch() {
      let startDate = moment(this.queryParams.startDate, "YYYY-MM-DD HH");
      let endDate = moment(this.queryParams.endDate, "YYYY-MM-DD HH");

      if (startDate.isAfter(endDate)) {
        this.$message.error("统计时间的开始时间不能超过结束时间！");
        return;
      }

      startDate = startDate.add(6, "months");

      if (endDate.isAfter(startDate)) {
        this.$message.error("统计时间的范围不能超过6个月！");
        return;
      }
      this.$refs.list.load();
    },
    getMonth() {
      // 获取当前月份的第一天
      this.queryParams.startDate = moment().startOf("month").format("YYYY-MM-DD HH");

      // 获取当前月份的最后一天
      this.queryParams.endDate = moment().endOf("month").format("YYYY-MM-DD HH");
      this.doSearch();
    },
    getNowDay() {
      // 获取当前月份的第一天
      this.queryParams.startDate = moment().startOf("day").format("YYYY-MM-DD HH");

      // 获取当前月份的最后一天
      this.queryParams.endDate = moment().endOf("day").format("YYYY-MM-DD HH");
      this.doSearch();
    },
    reset() {
      this.resetPara();
      this.$nextTick(() => this.doSearch());
    },
    resetPara() {
      this.queryParams = {
        username: "",
        detail: "",
        direction: "",
        answerflag: "",
        startDate: moment().startOf("day").format("YYYY-MM-DD HH"),
        endDate: moment().endOf("day").format("YYYY-MM-DD HH"),
        orderType: "desc",
        orderBy: this.selectList[0].value,
      };
    },
    noAnswerRow({ row, rowIndex }) {
      if (row.answerflag == 2) {
        return "no-answer-row";
      }
      return "";
    },
    exportExcel() {
      let url = `${this.baseUrls.callCdrReport}/callRecordExport`;
      let params = this.queryParams;
      axios
        .post(url, params, {
          transformRequest: [
            (params) => {
              return tansParams(params);
            },
          ],
          headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: getToken() },
          responseType: "blob",
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "通话记录.xlsx");
          document.body.appendChild(link);
          link.click();
        });
    },
  },
};
</script>
<style lang="scss">
.callRecordContainerFlexColumn {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f6f6f6;

  overflow: hidden;
  .el-date-editor {
    width: 170px;
  }

  &__top {
    background-color: white;

    .title {
      font-size: 18px;
    }

    padding: 10px;

    .el-divider {
      margin: 12px 0;
    }
  }

  .buttons {
    text-align: right;
    clear: both;
    padding-right: 10px;
    background-color: white;
  }

  &__body {
    flex: 1;
    border-top: none;
    padding: 10px;
    background-color: white;
  }
  .dc-form-item__body {
    .el-select {
      input {
        width: 170px;
      }
    }
  }
}
.customer-date-picker {
  .el-scrollbar:nth-of-type(2) {
    display: none !important;
  }
  .el-time-spinner {
    text-align: center;
  }
}

.no-answer-row {
  background-color: #ff0000 !important;
  color: white;
}
</style>
